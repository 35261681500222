input:-webkit-autofill,
 input:-webkit-autofill:hover,
 input:-webkit-autofill:focus
 textarea:-webkit-autofill,
 textarea:-webkit-autofill:hover
 textarea:-webkit-autofill:focus,
 select:-webkit-autofill,
 select:-webkit-autofill:hover,
 select:-webkit-autofill:focus {
     -webkit-box-shadow: 0 0 0px 1000px var(--loginInputBackgroundColor) inset;
     transition: background-color 5000s ease-in-out 0s;
     -webkit-text-fill-color: #eee;
     border-radius: var(--loginBorderRadus);
     padding: 1rem;
     background-color: var(--loginInputBackgroundColor) !important;
 }